import React, { useState } from 'react';
import styled, { css } from 'styled-components'
import Wyvern from './Wyvern'
import { useNavigate } from 'react-router'

const SwitchContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: ${props => props.small ? "6px" : "inherit"};
    position: relative;
    ${(props) => {
        switch (props.$position) {
            case "topLeft":
                return css` 
            left: 0.75rem;
            top: 0.75rem;
            }`;
            default:
                return css`
            right: 0.75rem;
            top: 0.75rem;
            `;
        }
    }}
`;

const Input = styled.input`
    display: block;
    height: 0;
    visibility: hidden;
    width: 0;

    &:checked + label {
        background: #7787c6;
    }

    &:checked + label:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }
`;

const Label = styled.label`
    background: #f3fafb; 
    border-radius: 100px;
    cursor: pointer;
    display: block;
    height: 30px;
    position: relative;
    text-indent: -99999px;
    width: 60px;

    &:after{
        background: #0f2b95;
        border-radius: 40px;
        content: '';
        height: 20px;
        left: 5px;
        position: absolute;
        top: 5px;
        width: 20px;        
    }

    &:active:after {
        width: 20px;
    }`

const WyvernToggle = (props) => {
    const { position } = props;
    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState(0);
    const navigate = useNavigate()

    const handleChange = () => {
        setChecked(!checked);
        setValue(value + 1);
        if (value === 1) {
            navigate(0)
            //window.location.reload(false);
        }
    }

    if (window.innerWidth >= 501) {
        return (
            <SwitchContainer $position={position}>
                <Input type="checkbox" id="switch" onChange={handleChange} checked={checked} />
                <Label htmlFor="switch"></Label>
                <span className="label">Enable Wyvern</span>
                {checked ? <Wyvern position={position} /> : null}
            </SwitchContainer>
        )
    } else {
        return (
            null
        );
    }
};

export default WyvernToggle;
