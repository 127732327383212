import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Layout from './pages/Layout';
import Home from './pages/Home';
import About from './pages/About';
import NoPage from "./pages/NoPage";
import './App.css';
import PuzzleWebsite from './pages/PuzzleWebsite';
import WorkoutApp from './pages/WorkoutApp';
import Cengage from './pages/Cengage';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path ="About/Cengage" element={<Cengage />} />
          <Route path ="About/workoutapp" element={<WorkoutApp />} />
          <Route path ="About/PuzzleWebsite" element={<PuzzleWebsite />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);




// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
