import { Outlet, Link } from "react-router-dom";
import { useState } from "react";

const Layout = () => {
    const [dark, setDark] = useState(false);

    const handleDarkToggl = () => {
        if (dark) {
            document.documentElement.className = 'light';
        } else {
            document.documentElement.className = 'dark';
        }
        setDark(!dark);

    }

    return (
        <>
            <div className="header-container">
                <header className="center">
                    <nav>
                        <Link to="/"><h1>Alessandra (Lexx) Anderson</h1></Link>
                        {/* above should be either h1 or span depending on page. */}
                        <Link to="/about">About</Link>
                    </nav>
                    <button className="togglDarkBtn" aria-hidden="true" tabIndex="-1" onClick={handleDarkToggl}>
                        <svg viewBox="0 0 200 200" id="sunmoon" width="100%" height="100%">
                            <defs>
                                <mask id="hole">
                                    <rect width="100%" height="100%" fill="white" />
                                    <circle id="overlay" r="80" cx="230" cy="-30" fill="black" className={dark ? "mooned" : ""} />
                                </mask>
                                <filter id="blur">
                                    <feDropShadow dx="0" dy="0" stdDeviation="10" floodColor="var(--light)" />
                                </filter>
                            </defs>

                            <g filter="url(#blur)">
                                <circle
                                    fill={dark ? "var(--light)" : "gold"}
                                    id="donut"
                                    r="80"
                                    cx="100"
                                    cy="100"
                                    mask="url(#hole)"
                                />
                            </g>
                        </svg>
                    </button>
                </header>
            </div>
            <Outlet />
            <footer>
                <blockquote>"Not All Who Wander Are Lost" ~J.R.R. Tolkien</blockquote>
                © 2023 Alessandra Anderson</footer>
        </>
    )
};

export default Layout;