import smolCleric from '../pictures/SmolCleric.png';
import figmaDesign from '../pictures/figmaDesign.png';
import filterSystem from '../pictures/filterSystem.png';

const WorkoutApp = () => {
    return (
        <div className='container'>
            <section className="center flexContainer">
                <div className="info">
                    <h2 className="center">The Guild</h2>
                    <p>An RPG workout visual novel where the user's activity pushes the story forward and unlocks characters, equipment, and xp all
                        while getting stronger!
                    </p>
                    <p>This is a personal project and is currently in development. Initially the project was created in Unity using C#.
                        I have since switched to using React and React-Native. 
                        Other technologies include Node.js, MongoDB, Adobe Suite, and Figma.
                    </p>
                    <p>
                        The image below is the implementation of a filtering system where a user can find workouts by type, time, and difficulty or a
                        combination of all three.
                    </p>
                </div>
                <div>
                    <img alt="small workout pal cleric" src={smolCleric} height="350px"/> 
                </div>
                <div>
                    <img alt="filter system" src={filterSystem} />
                </div>
            </section>
        </div>
    );
};

export default WorkoutApp;