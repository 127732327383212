import cengageRevamp from "../pictures/cengageRevamp.png";
import cengageRevamp2 from "../pictures/cengageRevamp2.png";

const Cengage = () => {
  return (
    <div className='container'>
      <section className="center flexContainer">
        <div className="info">
          <h2 className="center">Cengage Webassign Work</h2>
          <p>Created a new user experience as part of a team of five, including one QA and 4 software-engineers.
          </p>
          <p>This involved creating a new repo with Typescript and React, creating our own component library, documenting our new components using <a href="https://storybook.js.org/" target="_blank">Storybook</a> and working
            within Cengage's style-guide and a pre-existing code base using Perl.
          </p>
          <p>Furthermore we were able to practice both test-driven-development and mob programming (like pair programming, but even cooler!), and
            concentrate on both accessibility (WCAG), and responsive design </p>
          <p>The images and links below show some of what we did, but unfortunately most of the work is behind a paywall. I am happy to answer any questions
            about this project, or other projects at Cengage. You can also see more at: <a href="https://webassign.com/" target="_blank">Cengage Webassign</a>
          </p>
          <div className="center flexContainer">
            <div className="infoImage">
              <img alt="cengage webassign revamp" src={cengageRevamp} />
              <cite>Image from Cengage Website</cite>
            </div>
            <div className="infoImage">
              <img alt="cengage webassign revamp" src={cengageRevamp2} />
              <cite>Image from Cengage Website</cite>
            </div>
          </div>
        </div>

      </section>
    </div>
  );
};

export default Cengage;