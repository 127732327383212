import Selfie from '../pictures/selfie4.jpg';
import { Link } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css"

const Home = () => {
    return (
        <main>
            <div className="container welcomeMsg">
                <section className="center flexContainer">
                    <div className="info">
                        <h2>Well met, friend!</h2>
                        <p>I'm Lexx, a web developer with passion for art, storytelling and adventure!
                            I care a lot about accessible and responsive design choices and readability in code,
                            as well as being excited for the continuous advancement in web development.
                            Currently I'm located in the greater Boston area, and I spend my time working on personal projects
                            (ask me about my app!), traveling, writing stories, drawing, and playing games.
                            Pronouns are They/Them/She/Her.
                        </p>
                    </div>
                    <div className='infoContainer'>
                        <img className="roundImg" src={Selfie} alt="Lexx Anderson selfie" />
                        <div className='socialLinks'>
                            <a aria-label="visit Alessandra's linked in" href="https://www.linkedin.com/in/alessandraanderson/" rel="noreferrer" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="var(--accentColor)" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5
                                5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966
                                0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5
                                12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                                </svg>
                            </a>
                            <a aria-label="email Alessandra" href="mailto: Alessandra.R.Anderson@gmail.com" rel="noreferrer" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="var(--accentColor)" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M12 12.713l-11.985-9.713h23.971l-11.986 9.713zm-5.425-1.822l-6.575-5.329v12.501l6.575-7.172zm10.85
                                0l6.575 7.172v-12.501l-6.575 5.329zm-1.557 1.261l-3.868 3.135-3.868-3.135-8.11 8.848h23.956l-8.11-8.848z" />
                                </svg>
                            </a>
                        </div>
                    </div>
                </section>
                <a className="arrowLink" href="#MyServices">Learn More <span className='downArrow'>&#8595;</span></a>

            </div>
            <div className='container'>
                <section id="MyServices" className='center myServices'>
                    <h2> My Services</h2>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRightBig">
                        <div className='smallCenter flexContainer'>
                            <div>
                                <h3>Website Development</h3>
                                <p>I develop websites using modern HTML, CSS, Javascript and React, plus responsive and accessible design principles.
                                    I can either work from a design, and build a pixel perfect match, or we can work to make the perfect design together.</p>
                            </div>
                            <svg width="100" height="100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M15.246 17c-.927 3.701-2.547 6-3.246 7-.699-1-2.32-3.298-3.246-7h6.492zm7.664 0c-1.558 3.391-4.65 5.933-8.386 6.733 1.315-2.068 2.242-4.362 2.777-6.733h5.609zm-21.82 0h5.609c.539 2.386 1.47 4.678 2.777 6.733-3.736-.8-6.828-3.342-8.386-6.733zm14.55-2h-7.28c-.29-1.985-.29-4.014 0-6h7.281c.288 1.986.288 4.015-.001 6zm-9.299 0h-5.962c-.248-.958-.379-1.964-.379-3s.131-2.041.379-3h5.962c-.263 1.988-.263 4.012 0 6zm17.28 0h-5.963c.265-1.988.265-4.012.001-6h5.962c.247.959.379 1.964.379 3s-.132 2.042-.379 3zm-8.375-8h-6.492c.925-3.702 2.546-6 3.246-7 1.194 1.708 2.444 3.799 3.246 7zm-8.548-.001h-5.609c1.559-3.39 4.651-5.932 8.387-6.733-1.237 1.94-2.214 4.237-2.778 6.733zm16.212 0h-5.609c-.557-2.462-1.513-4.75-2.778-6.733 3.736.801 6.829 3.343 8.387 6.733z" /></svg>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
                        <div className='smallCenter flexContainer'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M4 21.832c4.587.38 2.944-4.493 7.188-4.538l1.838 1.534c.458 5.538-6.315 6.773-9.026 3.004zm14.065-7.115c1.427-2.239 5.847-9.749 5.847-9.749.352-.623-.43-1.273-.976-.813 0 0-6.572 5.714-8.511 7.525-1.532 1.432-1.539 2.086-2.035 4.447l1.68 1.4c2.227-.915 2.868-1.039 3.995-2.81zm-11.999 3.876c.666-1.134 1.748-2.977 4.447-3.262.434-2.087.607-3.3 2.547-5.112 1.373-1.282 4.938-4.409 7.021-6.229-1-2.208-4.141-4.023-8.178-3.99-6.624.055-11.956 5.465-11.903 12.092.023 2.911 1.081 5.571 2.82 7.635 1.618.429 2.376.348 3.246-1.134zm6.952-15.835c1.102-.006 2.005.881 2.016 1.983.004 1.103-.882 2.009-1.986 2.016-1.105.009-2.008-.88-2.014-1.984-.013-1.106.876-2.006 1.984-2.015zm-5.997 2.001c1.102-.01 2.008.877 2.012 1.983.012 1.106-.88 2.005-1.98 2.016-1.106.007-2.009-.881-2.016-1.988-.009-1.103.877-2.004 1.984-2.011zm-2.003 5.998c1.106-.007 2.01.882 2.016 1.985.01 1.104-.88 2.008-1.986 2.015-1.105.008-2.005-.88-2.011-1.985-.011-1.105.879-2.004 1.981-2.015zm10.031 8.532c.021 2.239-.882 3.718-1.682 4.587l-.046.044c5.255-.591 9.062-4.304 6.266-7.889-1.373 2.047-2.534 2.442-4.538 3.258z" /></svg>
                            <div>
                                <h3>Art</h3>
                                <p>Have a character you want drawn, or a fun sticker? I work digitally and am open to commissions! Check out some of my work <Link className="smallLink" to="/about#Art">here!</Link></p>
                            </div>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRightBig">
                        <div className='smallCenter flexContainer'>
                            <div>
                                <h3>GM</h3>
                                <p>Interested in playing D&D or another TTRPG? I'm a DM of 10+ years, a writer, and would love to run a game for you!</p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M10.467 14.944l-.108-.397c-.203-.875-1.812-2.312-3.25-2.422.562-.266 1.75-.969 1.859-1.516l-2.319-6.609-3.816 10.935c-1.654.429-2.833 1.173-2.833 2.234 0 1.887 3.317 2.831 6.633 2.831s6.631-.943 6.631-2.831c0-1.054-1.161-1.795-2.797-2.225zm-.062 3.051c-2.112.716-5.439.728-7.546 0-1.068-.369-1.264-.972-.593-1.396 1.693 1.515 7.06 1.516 8.76.005.694.427.466 1.022-.621 1.391zm1.662-12.694l-1.767 1.767 11.933 11.932 1.767-1.767-11.933-11.932zm1.326 3.977l-2.209-2.21.883-.884 2.21 2.21-.884.884zm1.082-3.653c.771.234 1.374.838 1.608 1.607.236-.77.838-1.373 1.608-1.607-.77-.235-1.373-.838-1.608-1.608-.235.769-.837 1.373-1.608 1.608zm-3.404 4.164c-.311 1.022-1.111 1.821-2.133 2.133 1.021.312 1.822 1.111 2.133 2.132.312-1.021 1.111-1.821 2.132-2.132-1.02-.312-1.82-1.111-2.132-2.133zm6.032-1.797c.545.167.971.593 1.138 1.137.166-.545.593-.971 1.137-1.137-.545-.166-.972-.592-1.138-1.137-.167.545-.592.971-1.137 1.137zm2.766-3.992c-.189.621-.676 1.107-1.297 1.297.622.19 1.108.676 1.298 1.297.189-.621.676-1.107 1.296-1.297-.619-.189-1.106-.676-1.297-1.297z" /></svg>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeftBig">
                        <div className='smallCenter flexContainer'>
                            <svg width="100" height="100" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M21 11c0-.552-.448-1-1-1s-1 .448-1 1c0 .551.448 1 1 1s1-.449 1-1m3 .486c-1.184 2.03-3.29 4.081-5.66 5.323-1.336-1.272-2.096-2.957-2.103-4.777-.008-1.92.822-3.704 2.297-5.024 2.262.986 4.258 2.606 5.466 4.478m-6.63 5.774c-.613.255-1.236.447-1.861.573-1.121 1.348-2.796 2.167-5.287 2.167-.387 0-.794-.02-1.222-.061.647-.882.939-1.775 1.02-2.653-2.717-1.004-4.676-2.874-6.02-4.287-1.038 1.175-2.432 2-4 2 1.07-1.891 1.111-4.711 0-6.998 1.353.021 3.001.89 4 1.999 1.381-1.2 3.282-2.661 6.008-3.441-.1-.828-.399-1.668-1.008-2.499.429-.04.837-.06 1.225-.06 2.467 0 4.135.801 5.256 2.128.68.107 1.357.272 2.019.495-1.453 1.469-2.271 3.37-2.263 5.413.008 1.969.773 3.799 2.133 5.224" /></svg>
                            <div>
                                <h3>Dive Master</h3>
                                <p>I'm an SSI certified divemaster who excels at finding cool fish, and being able to tell you about them.</p>
                            </div>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRightBig">
                        <div className='smallCenter flexContainer'>
                            <div>
                                <h3>Other?</h3>
                                <p>Need a mix of multiple skills? Have a game you want to develop? A weird one-off website-story hybrid? I'm happy to discuss all sorts of projects!</p>
                            </div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 18.25c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25s-.559 1.25-1.25 1.25zm1.961-5.928c-.904.975-.947 1.514-.935 2.178h-2.005c-.007-1.475.02-2.125 1.431-3.468.573-.544 1.025-.975.962-1.821-.058-.805-.73-1.226-1.365-1.226-.709 0-1.538.527-1.538 2.013h-2.01c0-2.4 1.409-3.95 3.59-3.95 1.036 0 1.942.339 2.55.955.57.578.865 1.372.854 2.298-.016 1.383-.857 2.291-1.534 3.021z" /></svg>
                        </div>
                    </AnimationOnScroll>
                </section>
            </div>
            <div className='container'>
                <section className='smallCenter center'>
                    <h2>Contact Me</h2>
                    <p className='centerText'>Interested in hiring me? Collaborating? Or just want to chat?
                        Feel free to send me an email at: Alessandra.R.Anderson@gmail.com
                        and I'll get back to you within twenty-four hours.</p>
                    {/* <form method="POST" id="contactForm" className='contactForm center'>
                            <label> Your Name
                                <input type="text" placeholder="John Doe" name="name" required="" />
                            </label>
                            <label> Your Email
                                <input type="email" placeholder="youremail@example.com" name="email"  required="" />
                            </label>
                            <label> Budget
                                <input type="text" placeholder="$ or N/A" name="name" />
                            </label>
                            <label> Message
                                <textarea type="text" placeholder="Hail fair traveler! I have a new quest, here are the details..." name="message" rows="5" required="" />
                            </label>
                            <button type="submit" className='center'>Send</button>
                        </form> */}
                </section>
            </div>
        </main>
    )
};

export default Home;
