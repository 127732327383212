import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components'
import base from './base.png'
import down from './down.png'
import fire from './fire.png'
import sit from './sit.png';


const wyvernImgs = [base, down, fire, sit];
const colorArray = ["#801100", "#B62203", "#D73502", "#FC6400", "#FF7500", "#FAC000"];
const Div = styled.div`
    background: rgba(255, 117, 0, 0.75);
    border-radius: 20px;
    box-shadow: 1px 5px 10px rgb(128, 17, 0);
    font-size: 1.5rem;
    font-family: 'Brush Script MT', cursive;
    font-weight: semi-bold;
    margin-right: 5px;
    padding: 5px;
    position: relative;
    text-align: center;
    width: 115px;
    z-index: 1;

    span {
        font-family: system-ui;
        font-size: 1rem;
    }
`;
const WyvernImg = styled.img`
    position: absolute;
    transform: ${props => props.flip ? "scaleX(-1)" : "scaleX(1)"};
    }}`

const Wyvern = (props) => {
    const { position } = props;
    let startPositionX = -200;
    if (position === "topLeft") {
        startPositionX = 0;
    }
    const [currentImg, setCurrentImg] = useState(0);
    const [xoffset, setxoffset] = useState(startPositionX);
    const [yoffset, setyoffset] = useState(0);
    const [flip, setFlip] = useState(true);
    const [tic, setTic] = useState(true);
    const [userInput, setUserInput] = useState(true);
    const wyvernRef = useRef();
    const delta = 11;

    useEffect(() => {
        function moveWyvern() {
            const { x, y } = wyvernRef.current.getBoundingClientRect();
            const { innerHeight, innerWidth } = window;

            if (x < 0) {
                setFlip(false);
            }
            if (x > innerWidth - 250) {
                setFlip(true);
            }
            if (y + 175 > innerHeight) {
                setTic(false);
            }

            if (flip) {
                setxoffset(xoffset - delta);
            } else {
                setxoffset(xoffset + delta);
            }
            if (!userInput) {
                if (xoffset % 8 === 0) {
                    setCurrentImg(1);
                } else {
                    setCurrentImg(0);
                }
            }
            setyoffset(yoffset + delta);
        }
        let interval;
        if (tic) {
            interval = setInterval(() => {
                moveWyvern();
            }, 150)
        }
        else {
            clearInterval(interval);
            setCurrentImg(3);
        }
        return () => clearInterval(interval);
    }, [flip, userInput, yoffset, xoffset, tic]);

    useEffect(() => {
        function handleKeyDown(e) {
            setUserInput(true);
            switch (e.keyCode) {
                case 87:
                    //up
                    setCurrentImg(1);
                    setTic(true);
                    moveWyvernUp();
                    break;
                case 70:
                    //fire
                    const { x, y } = wyvernRef.current.getBoundingClientRect();
                    const randColor = Math.floor(Math.random() * 6);
                    let elem;
                    if (flip) {
                        elem = document.elementFromPoint(x - 5, y + 100);
                    } else {
                        elem = document.elementFromPoint(x + 261, y + 100);
                    }
                    if (elem.tagName === "IMG") {
                        elem.style.boxShadow = `rgb(85, 91, 255) 0px 0px 2px 3px,
                                                rgb(31, 193, 27) 0px 0px 2px 5px,
                                                rgb(255, 217, 19) 0px 0px 2px 8px,
                                                rgb(255, 156, 85) 0px 0px 2px 11px,
                                                rgb(255, 85, 85) 0px 0px 2px 14px`;
                    }
                    if (elem) {
                        elem.style.background = colorArray[randColor];
                    }
                    console.log('elem is:', elem.tagName);

                    if (y + 175 > window.innerHeight) {
                        setCurrentImg(2);
                        setTic(true);
                        moveWyvernUp();
                    } else {
                        setCurrentImg(2);
                        setTic(true);
                    }
                    break;
                default: setTic(false);
            };
        }
        function handleKeyUp(e) {
            setUserInput(false);
        }
        const moveWyvernUp = () => {
            const { x, y } = wyvernRef.current.getBoundingClientRect();

            if (x < 0) {
                setFlip(false);
            }
            if (x > window.innerWidth - 250) {
                setFlip(true);
            }
            if (y < -50) {
                setyoffset(yoffset + 1);
                return;
            }

            setyoffset(yoffset - (delta + 60));
            if (flip) {
                setxoffset(xoffset - delta);
            } else {
                setxoffset(xoffset + delta);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return function cleanup() {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyDown);
        }
    }, [yoffset, xoffset, flip]);

    return (
        [<WyvernImg alt="a wyvern flying" key={0} ref={wyvernRef} flip={flip} src={wyvernImgs[currentImg]} style={{ left: `${xoffset}px`, top: `${yoffset}px`, }} />,
        <Div key={1}>Instructions:
            <span>
                <br /><b>f</b> to breathe fire
                <br /><b>w</b> to go up
            </span>
        </Div>]
    );
};

export default Wyvern;