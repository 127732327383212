import sitePreview from "../pictures/sitePreview.gif";

const PuzzleWebsite = () => {
  return (
    <div className='container'>
      <section className="center flexContainer">
        <div className="info">
          <h2 className="center">Game Master Puzzle Website</h2>
          <p>A website with puzzles, riddles, and other tools for all your game master needs while running ttrpgs.
          </p>
          <p>This is a personal project and a mentorship project for a friend who wanted to pick up some coding basics. We're utilizing 
            React, vanilla JS, HTML, CSS and various video game puzzles to walk through some coding principles in a fun way.
            Everything on this site uses open-source svgs, some fun clip-pathing, and playing with CSS animations and styles. So far we've 
            coded a mastermind (WORDLE) puzzle, a light grid puzzle, riddles, and some filtering and search functionality.
          </p>
          <p>
            The image below depicts a 'light' puzzle with some fun CSS designs and an interesting double array grid for the logic.
          </p>
          <div className="center">
            <img alt="gif of light puzzle" src={sitePreview} width="500px" />
          </div>
        </div>

      </section>
    </div>
  );
};

export default PuzzleWebsite;